<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { fetchClubList } from "@/api/home.js";
export default {
  data() {
    return {
      navigations: [
        {
          title: "首页",
          name: "Home",
          children: null,
          id: 1
        },
        {
          title: "青企概况",
          name: "Survey",
          id: 2,
          children: [
            {
              title: "青企简介",
              component: "SurveyAbout"
            },
            {
              title: "青企章程",
              component: "SurveyCharter"
            },
            {
              title: "组织架构",
              component: "SurveySchema"
            },
            {
              title:"会员页面",
              component: "SurveyMember"
            },
            {
              title: "入会表格",
              component: "SurveyTable"
            },
            {
              title: "联系我们",
              component: "SurveyContact"
            }
          ]
        },
        {
          title: "青企新闻",
          name: "News",
          id: 3,
          children: [
            // {
            //   title: "青企头条",
            //   component: "NewsMatter"
            // },
            {
              title: "青企要闻",
              component: "NewsDynamic"
            },
            {
              title: "青企动态",
              component: "NewsActive"
            }
          ]
        },
        {
          title: "青商学堂",
          name: "School",
          id: 4,
          children: [
            {
              title: "学堂简介",
              component: "SchoolAbout"
            },
            {
              title: "品牌项目",
              component: "BrandProject"
            },
            {
              title: "学堂动态",
              component: "SchoolDynamic"
            },
            {
              title: "学堂推介",
              component: "SchoolRecommend"
            }
          ]
        },
        {
          title: "俱乐部",
          name: "Club",
          id: 5,
          children: [
            {
              title: "俱乐部动态",
              id: -1
            }
          ]
        },
        {
          title: "益基金",
          name: "Benefit",
          id: 6,
          children: [
            {
              title: "基金简介",
              component: "BenefitAbout"
            },
            {
              title: "品牌项目",
              component: "BrandProject"
            },
            {
              title: "基金动态",
              component: "BenefitDynamic"
            },
            {
              title: "公益推介",
              component: "BenefitPromotion"
            }
          ]
        },
        {
          title: "创基金",
          name: "Creator",
          id: 7,
          children: [
            {
              title: "基金简介",
              component: "BenefitAbout"
            },
            {
              title: "品牌项目",
              component: "BrandProject"
            },
            {
              title: "基金动态",
              component: "BenefitDynamic"
            },
            {
              title: "公益推介",
              component: "BenefitPromotion"
            }
          ]
        }
      ]
    };
  },
  methods: {
    async getClubList() {
      const { result } = await fetchClubList();
      const list = result.data.map(item => {
        return {
          title: item.name,
          id: item.id
        };
      });
      let club = this.navigations.find(item => item.title === "俱乐部");
      club.children.push(...list);
      this.$store.commit("SET_CULB_LIST", club.children);
      this.$store.commit("SET_NAV_LIST", this.navigations);
    }
  },
  mounted() {
    this.getClubList();
  }
};
</script>

<style>
#html-content img {
   object-fit: contain;
  }
</style>