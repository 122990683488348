import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    clubList: [],
    navList: []
  },
  mutations: {
    SET_CULB_LIST(state, list) {
      state.clubList = list;
    },
    SET_NAV_LIST(state, list) {
      state.navList = list;
    }
  },
  actions: {},
  modules: {},
  getters
});
