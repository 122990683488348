<template>
  <div class="head-wrapper">
    <div class="top-head frcb">
      <div class="logo-img-box">
        <img src="@/assets/images/logo.png" />
      </div>
      <div class="frc">
        <div class="page-title">{{ title }}</div>
        <div class="slash">
          /
        </div>
        <div class="menu-icon" @click="handlerShow">
          <img src="@/assets/images/menu.png" />
        </div>
      </div>
      <van-popup
        v-model="show"
        position="left"
        :close-on-click-overlay="false"
        :style="{ height: '100%', width: '84vw' }"
      >
        <div class="pop-logo-box frc">
          <img class="pop-logo-img" src="@/assets/images/logo.png" alt="" />
        </div>
        <div
          class="navigation-item-box "
          v-for="(item, index) in navList"
          :key="index"
          @click="jumpPath(item, index)"
        >
          <div class="navigation-item frcb">
            <div class="navigation-title">
              {{ item.title }}
            </div>
            <div class="arrow-icon">
              <img
                class="arrow-img"
                :class="item.children && currentIndex == index ? 'active' : ''"
                src="@/assets/images/rught_arrow.png"
              />
            </div>
          </div>

          <div
            class="child-item-box"
            :style="{maxHeight: item.children && currentId == item.id ? `${ 12 * item.children.length}vw` : '0'}"
          >
            <div
              class="child-item"
              @click="gotoPath(item, child)"
              v-for="(child, i) in item.children"
              :key="i"
            >
              {{ child.title }}
            </div>
          </div>
        </div>
      </van-popup>
      <div class="close-box frcc" v-show="show" @click="closePop">
        <div class="close-border">
          <img src="@/assets/images/close.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopHead",
  data() {
    return {
      show: false,
      currentId: -1,
      currentIndex: -1
    };
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
    navList() {
      return this.$store.getters.navList;
    }
  },
  methods: {
    handlerShow() {
      this.show = true;
    },
    closePop() {
      this.show = false;
      this.currentId = -1;
      this.currentIndex = -1;
    },
    gotoPath(item, child) {
      this.closePop();
      if (item.title === "俱乐部") {
        if (item.id === -1) {
          this.$router.push({
            name: item.name,
            params: {
              id: child.id
            }
          });
        } else {
          this.$router.push({
            name: item.name,
            params: {
              id: child.id
            }
          });
        }
      } else {
        this.$router.push({
          name: item.name,
          params: {
            component: child.component
          }
        });
      }
    },
    jumpPath(item, index) {
      if (this.currentId == item.id) {
        this.currentId = 0;
        this.currentIndex = -1;
        return;
      }
      if (item.children) {
        this.currentId = item.id;
        this.currentIndex = index;
      } else {
        this.$router.push({ name: item.name });
        this.closePop();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.head-wrapper {
  margin-top: 15vw;
}
.top-head {
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 9999;
  box-sizing: border-box;
  padding: 0 4vw;
  height: 15vw;
  background: #ffffff;

  .logo-img-box {
    width: 34vw;
    height: 9vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .page-title {
    font-family: PingFang-SC-Regular;
    font-size: 5vw;
    color: #404040;
  }
  .slash {
    margin: 0 4vw 0 1vw;
    font-family: PingFang-SC-Regular;
    font-size: 5vw;
    color: #404040;
  }
  .menu-icon {
    width: 6vw;
    height: 4vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.close-box {
  position: fixed;
  z-index: 9999999;
  right: 0;
  top: 0;
  width: 16vw;
  height: 15vw;
  background-color: #007069;
  .close-border {
    width: 6vw;
    height: 6vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.pop-logo-box {
  padding-left: 4vw;
  height: 15vw;
  .pop-logo-img {
    height: 9vw;
    object-fit: cover;
  }
}
.navigation-item-box {
  padding: 0 5vw 0 7vw;
  &:nth-child(even) {
    background: #f4f4f4;
    .child-item-box {
      background: #f4f4f4;
    }
  }
  .navigation-item {
    height: 15vw;
    font-family: PingFang-SC-Medium;
    font-size: 4vw;
    color: #333333;
    .arrow-icon {
      height: 4vw;
      .arrow-img {
        height: 100%;
        transition: all 0.3s ease-in-out;
        &.active {
          transform: rotate(90deg);
        }
      }
    }
  }
}
.child-item-box {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  background: #ffffff;
  &.active {
    max-height: 75vw;
  }
  .child-item {
    margin: 3vw 0 6vw 0;
    font-family: PingFang-SC-Medium;
    font-size: 3vw;
    color: #404040;
  }
}
</style>
