import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//vant组件库
import Vant from "vant";
//vant组件样式
import "vant/lib/index.css";
//全局公用样式
import "@/assets/css/common.css";
import "@/assets/css/quill.core.css";
import "@/assets/css/quill.snow.css";
//全局过滤器
import "@/utils/filter";

Vue.config.productionTip = false;

Vue.use(Vant);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
