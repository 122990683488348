<template>
  <div class="home">
    <top-head></top-head>
    <swiper-box :height="'auto'" :list="swipers"></swiper-box>
    <van-tabs
      v-model="active"
      title-active-color="#007069"
      color="#007069"
      type="line"
      :border="true"
    >
      <van-tab title="青企要闻">
        <div class="article-column">
          <div class="article-list-box">
            <div
              class="article-list-item"
              v-for="(item, index) in budgets"
              :key="index"
              @click="
                $router.push({
                  name: 'NewsDetails',
                  params: { id: item.id, title: '要闻详情' }
                })
              "
            >
              <div class="article-list-item-left-line"></div>
              <div class="article-list-item-right">
                <div class="article-list-item-title">
                  {{ item.title }}
                </div>
                <div class="article-list-item-time">
                  {{ item.send_time || item.create_time }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="see-more-btn frcc"
            @click="
              $router.push({
                name: 'News',
                params: {
                  component: 'NewsDynamic'
                }
              })
            "
          >
            查看更多
          </div>
        </div>
      </van-tab>
      <van-tab title="青企动态">
        <div class="article-column" v-if="trends.length > 1">
          <div class="article-img-box">
            <div
              class="article-big-img"
              @click="
                $router.push({
                  name: 'NewsDetails',
                  params: { id: trends[0].id, title: '动态详情' }
                })
              "
            >
              <img :src="trends[0].head_pic" />
              <div class="mask"></div>
              <div class="mask-title-box fc">
                <div class="mask-title">
                  {{ trends[0].title }}
                </div>
              </div>
            </div>
            <div class="article-smll-img frc">
              <div
                class="article-big-img-left"
                @click="
                  $router.push({
                    name: 'NewsDetails',
                    params: { id: trends[1].id, title: '动态详情' }
                  })
                "
              >
                <img :src="trends[1].head_pic" alt="" />
                <div class="mask"></div>
                <div class="mask-title-box fc">
                  <div class="mask-smll-title">
                    {{ trends[1].title }}
                  </div>
                </div>
              </div>
              <div
                class="article-big-img-right"
                @click="
                  $router.push({
                    name: 'NewsDetails',
                    params: { id: trends[2].id, title: '动态详情' }
                  })
                "
              >
                <img :src="trends[2].head_pic" alt="" />
                <div class="mask"></div>
                <div class="mask-title-box fc">
                  <div class="mask-smll-title">
                    {{ trends[2].title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="see-more-btn frcc"
            @click="
              $router.push({
                name: 'News',
                params: {
                  component: 'NewsActive'
                }
              })
            "
          >
            查看更多
          </div>
        </div>
      </van-tab>
      <van-tab title="俱乐部动态">
        <div class="article-column">
          <div class="club-list frc">
            <div class="club-item"  @click="
                $router.push({
                  name: 'NewsDetails',
                  params: { id: item.id, title: '动态详情' }
                })
              " v-for="(item, index) in clubs" :key="index">
              <div class="club-cover">
                <img :src="item.head_pic" alt="" />
              </div>
              <div class="club-title">
                {{ item.title }}
              </div>
              <div class="club-time">
                {{ item.create_time }}
              </div>
            </div>
          </div>
          <div
            class="see-more-btn frcc"
            @click="
              $router.push({
                name: 'Club',
                params: {
                  id: -1
                }
              })
            "
          >
            查看更多
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <induction-box></induction-box>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
import TopHead from "@/components/TopHead";
import SwiperBox from "@/components/SwiperBox";
import InductionBox from "../components/InductionBox.vue";
import BottomBar from "../components/BottomBar.vue";
import { fetchSwiper, fetchArticleList } from "@/api/home.js";
export default {
  name: "Home",
  data() {
    return {
      swipers: [],
      active: 0,
      headlines: [],
      budgets: [],
      trends: [],
      clubs: []
    };
  },
  mounted() {
    this.getSwiper();
    this.getData();
  },
  methods: {
    getData() {
      this.getHeadlines();
      this.getBudgets();
      this.getTrends();
      this.getClubs();
    },
    async getHeadlines() {
      const { result } = await fetchArticleList({
        pageSize: 1,
        pageNo: 1,
        cate_id: 8
      });
      this.headlines = result.data;
    },
    async getBudgets() {
      const { result } = await fetchArticleList({
        pageSize: 3,
        pageNo: 1,
        cate_id: 6
      });
      this.budgets = result.data;
    },
    async getTrends() {
      const { result } = await fetchArticleList({
        pageSize: 3,
        pageNo: 1,
        cate_id: 7
      });
      this.trends = result.data;
    },
    async getClubs() {
      const { result } = await fetchArticleList({
        pageSize: 4,
        pageNo: 1,
        cate_id: 32
      });
      console.log(result);
      this.clubs = result.data;
    },
    async getSwiper() {
      const { result } = await fetchSwiper({
        type: 1
      });
      this.swipers = result.data;
    }
  },
  components: {
    TopHead,
    SwiperBox,
    InductionBox,
    BottomBar
  }
};
</script>

<style lang="less" scoped>
.article-column {
  padding: 4vw 3vw 0 3vw;
  box-sizing: border-box;
  .cover-img-box {
    height: 62vw;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .article-title {
    margin: 4vw 0 3vw 0;
    font-family: PingFang-SC-Medium;
    font-size: 4vw;
    color: #404040;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .release-time {
    font-family: PingFang-SC-Regular;
    font-size: 3vw;
    color: #a2a2a3;
  }
  .article-intro {
    margin-top: 4vw;
    font-family: PingFang-SC-Medium;
    font-size: 3vw;
    color: #404040;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}
.see-more-btn {
  margin: 9vw 0 12vw 0;
  height: 13vw;
  border: 1px solid #007069;
  border-radius: 1vw;
  font-family: PingFang-SC-Medium;
  font-size: 4vw;
  color: #007069;
}

.article-list-box {
  display: flex;
  flex-direction: column;
  // align-items: flex-end;
}

.article-list-item {
  // width: 88vw;
  box-sizing: border-box;
  position: relative;
  .article-list-item-right {
    // width: 79vw;
    border-bottom: 1px solid #eeeeee;
  }
  .article-list-item-title {
    padding-top: 5vw;
    font-size: 4vw;
    color: #404040;
  }
  .article-list-item-time {
    margin: 5vw 0 4vw 0;
    font-size: 3vw;
    color: #a2a2a3;
  }
  // .article-list-item-left-line {
  //   left: 0;
  //   position: absolute;
  //   height: 100%;
  //   width: 1px;
  //   background: #007069;
  // }
}

.article-big-img {
  position: relative;
  height: 70vw;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.mask-title-box {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  .mask-smll-title {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    left: 3vw;
    font-family: PingFang-SC-Bold;
    font-size: 4vw;
    color: #ffffff;
    bottom: 5vw;
  }
}
.mask-title {
  position: absolute;
  bottom: 7vw;
  width: 82vw;
  font-family: PingFang-SC-Bold;
  font-size: 4vw;
  color: #ffffff;
}

.article-big-img {
  width: 100%;
  margin-bottom: 0.5vw;
}

.article-smll-img {
  width: 100%;
  height: 35vw;
  .article-big-img-left {
    margin-right: 0.5vw;
  }
  .article-big-img-left,
  .article-big-img-right {
    position: relative;
    width: calc(100% / 2);
    height: 35vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.club-list {
  flex-wrap: wrap;
  justify-content: space-between;
  .club-item {
    width: 46vw;
    margin-bottom: 4vw;
    .club-cover {
      img {
        width: 100%;
        height: 31vw;
        object-fit: cover;
        vertical-align: bottom;
      }
    }
    .club-title {
      margin: 4vw 0;
      font-family: PingFang-SC-Medium;
      font-size: 3vw;
      color: #404040;
    }
    .club-time {
      font-family: PingFang-SC-Regular;
      font-size: 3vw;
      color: #a2a2a3;
    }
  }
}
</style>
