import { axios } from "@/utils/request";

// import api from "./index";

//获取首页轮播图
export function fetchSwiper(params) {
  return axios({
    url: "/rotation",
    method: "get",
    params
  });
}

//文章分类列表
export function fetchArticleCategory() {
  return axios({
    url: "/writingtag",
    method: "get"
  });
}

//获取文章列表
export function fetchArticleList(params) {
  return axios({
    url: "/writings",
    method: "get",
    params
  });
}
//获取活动列表
export function fetchActiveList(params) {
  return axios({
    url: "/activity",
    method: "get",
    params
  });
}
//获取青企简介
export function fetchAbout() {
  return axios({
    url: "/config/1",
    method: "get"
  });
}

export function fetchClubList() {
  return axios({
    url: "/club",
    method: "get"
  });
}
//获取会员列表
export function fetchmembers(params) {
  return axios({
    url: "/membershipapply/all",
    method: 'get',
    params
  })
}
