var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('top-head'),_c('swiper-box',{attrs:{"height":'auto',"list":_vm.swipers}}),_c('van-tabs',{attrs:{"title-active-color":"#007069","color":"#007069","type":"line","border":true},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":"青企要闻"}},[_c('div',{staticClass:"article-column"},[_c('div',{staticClass:"article-list-box"},_vm._l((_vm.budgets),function(item,index){return _c('div',{key:index,staticClass:"article-list-item",on:{"click":function($event){return _vm.$router.push({
                name: 'NewsDetails',
                params: { id: item.id, title: '要闻详情' }
              })}}},[_c('div',{staticClass:"article-list-item-left-line"}),_c('div',{staticClass:"article-list-item-right"},[_c('div',{staticClass:"article-list-item-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"article-list-item-time"},[_vm._v(" "+_vm._s(item.send_time || item.create_time)+" ")])])])}),0),_c('div',{staticClass:"see-more-btn frcc",on:{"click":function($event){return _vm.$router.push({
              name: 'News',
              params: {
                component: 'NewsDynamic'
              }
            })}}},[_vm._v(" 查看更多 ")])])]),_c('van-tab',{attrs:{"title":"青企动态"}},[(_vm.trends.length > 1)?_c('div',{staticClass:"article-column"},[_c('div',{staticClass:"article-img-box"},[_c('div',{staticClass:"article-big-img",on:{"click":function($event){return _vm.$router.push({
                name: 'NewsDetails',
                params: { id: _vm.trends[0].id, title: '动态详情' }
              })}}},[_c('img',{attrs:{"src":_vm.trends[0].head_pic}}),_c('div',{staticClass:"mask"}),_c('div',{staticClass:"mask-title-box fc"},[_c('div',{staticClass:"mask-title"},[_vm._v(" "+_vm._s(_vm.trends[0].title)+" ")])])]),_c('div',{staticClass:"article-smll-img frc"},[_c('div',{staticClass:"article-big-img-left",on:{"click":function($event){return _vm.$router.push({
                  name: 'NewsDetails',
                  params: { id: _vm.trends[1].id, title: '动态详情' }
                })}}},[_c('img',{attrs:{"src":_vm.trends[1].head_pic,"alt":""}}),_c('div',{staticClass:"mask"}),_c('div',{staticClass:"mask-title-box fc"},[_c('div',{staticClass:"mask-smll-title"},[_vm._v(" "+_vm._s(_vm.trends[1].title)+" ")])])]),_c('div',{staticClass:"article-big-img-right",on:{"click":function($event){return _vm.$router.push({
                  name: 'NewsDetails',
                  params: { id: _vm.trends[2].id, title: '动态详情' }
                })}}},[_c('img',{attrs:{"src":_vm.trends[2].head_pic,"alt":""}}),_c('div',{staticClass:"mask"}),_c('div',{staticClass:"mask-title-box fc"},[_c('div',{staticClass:"mask-smll-title"},[_vm._v(" "+_vm._s(_vm.trends[2].title)+" ")])])])])]),_c('div',{staticClass:"see-more-btn frcc",on:{"click":function($event){return _vm.$router.push({
              name: 'News',
              params: {
                component: 'NewsActive'
              }
            })}}},[_vm._v(" 查看更多 ")])]):_vm._e()]),_c('van-tab',{attrs:{"title":"俱乐部动态"}},[_c('div',{staticClass:"article-column"},[_c('div',{staticClass:"club-list frc"},_vm._l((_vm.clubs),function(item,index){return _c('div',{key:index,staticClass:"club-item",on:{"click":function($event){return _vm.$router.push({
                name: 'NewsDetails',
                params: { id: item.id, title: '动态详情' }
              })}}},[_c('div',{staticClass:"club-cover"},[_c('img',{attrs:{"src":item.head_pic,"alt":""}})]),_c('div',{staticClass:"club-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"club-time"},[_vm._v(" "+_vm._s(item.create_time)+" ")])])}),0),_c('div',{staticClass:"see-more-btn frcc",on:{"click":function($event){return _vm.$router.push({
              name: 'Club',
              params: {
                id: -1
              }
            })}}},[_vm._v(" 查看更多 ")])])])],1),_c('induction-box'),_c('bottom-bar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }