<template>
  <div class="bottom-bar">
    <div class="gradient-line"></div>
    <div class="copyright fc">
      <div class="copyright-owners">
        顺德青年企业家协会 @2020-2021 All Rights Reserved
      </div>
      <div class="for-record">
        粤ICP备17025849号-1
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomBar"
};
</script>

<style lang="less" scoped>
.gradient-line {
  width: 100vw;
  height: 1vw;
  background-image: linear-gradient(90deg, #bfe063 0%, #67dfc4 100%),
    linear-gradient(#283232, #283232);
}
.copyright {
  height: 17vw;
  background: #1e1c1f;
}
.copyright-owners {
  margin-top: 3vw;
  font-family: PingFang-SC-Regular;
  font-size: 3vw;
  color: #99979a;
}
.for-record {
  margin-top: 1vw;
  font-size: 3vw;
  color: #4f4d50;
}
</style>
