import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "首页"
    }
  },
  {
    path: "/survey/:component",
    name: "Survey",
    component: () => import("@/views/survey"),
    meta: {
      title: "青企概况"
    }
  },
  {
    path: "/news/:component",
    name: "News",
    component: () => import("@/views/news"),
    meta: {
      title: "青企新闻",
      keepAlive: true
    }
  },
  {
    path: "/news/:id/details/:title",
    name: "NewsDetails",
    component: () => import("@/views/news/newsDetails"),
    meta: {
      title: "青企新闻"
    }
  },
  {
    path: "/school/:component",
    name: "School",
    component: () => import("@/views/school"),
    meta: {
      title: "青商学堂"
    }
  },
  {
    path: "/brand/:id/details",
    name: "BrandDetails",
    component: () => import("@/views/school/brandProjectDetails"),
    meta: {
      title: "青商学堂"
    }
  },
  {
    path: "/benefit/:component",
    name: "Benefit",
    component: () => import("@/views/benefit"),
    meta: {
      title: "益基金"
    }
  },
  {
    path: "/creator/:component",
    name: "Creator",
    component: () => import("@/views/creator"),
    meta: {
      title: "创基金"
    }
  },
  {
    path: "/brandProjectDetails/:id/details/:pid",
    name: "BrandProjectDetails",
    component: () => import("@/views/benefit/brandProjectDetails"),
    meta: {
      title: "益基金"
    }
  },
  {
    path: "/brandProject/:id/details/:pid",
    name: "CreatorProjectDetails",
    component: () => import("@/views/creator/brandProject"),
    meta: {
      title: "创基金"
    }
  },
  {
    path: "/dynamic/:id/details/:pid",
    name: "CreatorDynamicDetails",
    component: () => import("@/views/creator/creatorDynamic"),
    meta: {
      title: "创基金"
    }
  },
  {
    path: "/promotion/:id/details/:pid",
    name: "CreatorPromotionDetails",
    component: () => import("@/views/creator/creatorPromotion"),
    meta: {
      title: "创基金"
    }
  },
  {
    path: "/brand/:id/details/:pid",
    name: "SchoolBrandProjectDetails",
    component: () => import("@/views/school/brandProjectDetails"),
    meta: {
      title: "青商学堂"
    }
  },
  {
    path: "/dynamic/:id/details/:pid",
    name: "DynamicDetails",
    component: () => import("@/views/school/dynamicDetails"),
    meta: {
      title: "青商学堂"
    }
  },
  {
    path: "/promotion/:id/details/:pid",
    name: "SchoolPromotion",
    component: () => import("@/views/school/PromotionDetails"),
    meta: {
      title: "青商学堂"
    }
  },
  {
    path: "/club/:id",
    name: "Club",
    component: () => import("@/views/club"),
    meta: {
      title: "俱乐部"
    }
  },
  {
    path: "/club/:id/notice",
    name: "ClubNotice",
    component: () => import("@/views/club/notice"),
    meta: {
      title: "俱乐部"
    }
  },
  {
    path: "/club/:id/dynamic",
    name: "ClubDynamic",
    component: () => import("@/views/club/dynamic"),
    meta: {
      title: "俱乐部"
    }
  },
  {
    path: "/club/notice/:pid/details/:id",
    name: "ClubNoticeDetails",
    component: () => import("@/views/club/noticeDetails"),
    meta: {
      title: "俱乐部"
    }
  },
  {
    path: "/club/dynamic/:pid/details/:id",
    name: "ClubDynamicDetails",
    component: () => import("@/views/club/dynamicDetails"),
    meta: {
      title: "俱乐部"
    }
  },
  {
    path: "/document/:id",
    name: "Document",
    component: () => import("@/views/document"),
    meta: {
      title: "文章"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, form, savedPosition) {
    const routers = [
      "NewsDetails",
      "BrandProjectDetails",
      "Benefit",
      "DynamicDetails",
      "SchoolPromotion",
      "SchoolBrandProjectDetails"
    ];
    if (routers.indexOf(to.name) != -1) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: to.meta.savedPosition || 0 };
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
