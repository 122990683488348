<template>
  <div class="swiper-box" :style="{ height }">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#007069">
      <van-swipe-item v-for="(item, index) in list" :key="index">
        <template>
          <div v-if="typeof item != 'string'">
            <a :href="item.content" target="_blank">
              <img class="swiper-img" :src="item.url" />
            </a>
          </div>
          <div v-else>
            <img class="swiper-img" :src="item" />
          </div>
        </template>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: "SwiperBox",
  props: {
    list: {
      type: Array,
      default: () => [],
      require: true
    },
    height: {
      type: String,
      default: "100px",
      require: true
    }
  }
};
</script>

<style lang="less" scoped>
.swiper-box {
  .my-swipe {
    height: 100%;
  }
  .swiper-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>
