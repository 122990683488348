<template>
  <!-- <div class="induction-box">
    <div class="bg-img-box">
      <img
        src="https://images.pexels.com/photos/323705/pexels-photo-323705.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        alt=""
      />
    </div>
    <div class="msk"></div>
    <div class="apply-intro-box fc">
      <div class="apply-en-title">
        APPLY FOR MEMBERSHIP
      </div>
      <div class="apply-intro">
        这里是一句青企协的口号或者其他可编辑内容 这是第二行显示效果
      </div>
      <div class="apply-btn frcc" @click="$router.push({name:'Survey', params:{ component: 'SurveyTable' }})">
        申请入会
      </div>
    </div>
  </div> -->
  <div></div>
</template>

<script>
export default {
  name: "InductionBox"
};
</script>

<style lang="less" scoped>
.induction-box {
  position: relative;
  height: 77vw;
  .bg-img-box {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .msk {
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  .apply-intro-box {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    .apply-en-title {
      margin-top: 11vw;
      font-family: PingFang-SC-Medium;
      font-size: 3vw;
      color: #ffffff;
    }
    .apply-intro {
      margin-top: 7vw;
      padding: 0 5vw;
      font-size: 4vw;
      color: #ffffff;
      text-align: center;
    }
    .apply-btn {
      margin-top: 15vw;
      width: 36vw;
      height: 10vw;
      background-color: #ffffff;
      border-radius: 1vw;
      font-family: PingFang-SC-Bold;
      font-size: 4vw;
      color: #9d7cb3;
    }
  }
}
</style>
